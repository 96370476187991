import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';

import classes from '../../integrations/Guesty/components/ActivateGuesty/activateGuesty.module.css';
import oauthClasses from './activateUsingOauth.module.css';

import { ReactComponent as AlertIcon } from 'asset/IconsPack/at_alert-circle-filled.svg';
import IntegrationLoader from 'asset/img/integration_loading.gif';

const ActivateUsingOauth = ({
    isConnectionError,
    isExchangingToken,
    integrationName,
    connectUrl,
    Logo,
    redirectUri,
}) => {
    const history = useHistory();

    const handleRetry = () => history.push(`/integrations/${integrationName.toLowerCase()}`);

    const renderContent = () => {
        switch (true) {
            case isConnectionError:
                return (
                    <>
                        <AlertIcon className={oauthClasses.alterIcon} />
                        <Typography className={oauthClasses.connectionError}>
                            There was an error connecting to {integrationName}.
                        </Typography>
                        <Button
                            onClick={handleRetry}
                            intent="primary"
                            className={classes.primaryBtn}
                        >
                            Retry To Connect
                        </Button>
                    </>
                );
            case isExchangingToken:
                return (
                    <>
                        <img src={IntegrationLoader} alt="loading-animation" />
                        <Logo className={oauthClasses.logo} />
                        <Typography className={classes.description}>
                            Please wait while we are connecting to {integrationName}
                        </Typography>
                    </>
                );
            default:
                return (
                    <Box className={oauthClasses.container}>
                        <Logo className={oauthClasses.logo} />
                        <Button
                            intent="primary"
                            href={`${connectUrl}${
                                redirectUri ? `&redirectUri=${redirectUri}` : ''
                            }`}
                            className={classes.primaryBtn}
                        >
                            Connect To {integrationName}
                        </Button>
                    </Box>
                );
        }
    };

    return (
        <Box className={classes.main}>
            <Box className={classes.container}>{renderContent()}</Box>
        </Box>
    );
};

ActivateUsingOauth.propTypes = {
    isConnectionError: PropTypes.bool,
    isExchangingToken: PropTypes.bool,
    integrationName: PropTypes.string.isRequired,
    connectUrl: PropTypes.string.isRequired,
    Logo: PropTypes.elementType.isRequired,
    redirectUri: PropTypes.string,
};

export default ActivateUsingOauth;
