import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import config from 'config';
import Dashboard from 'layouts/V2/Dashboard';

import actionConstants from 'redux/actions/actionConstant';
import { selectCompanyAccessCode } from 'redux/selectors/auth';
import { selectIntegrations, selectOwnerRezActiveState } from 'redux/selectors/integrations';
import {
    useGetDefaultBrandQuery,
    useDeactivateIntegration,
    useExchangeToken,
} from 'redux/slices/globalApi';
import { setActiveIntegrations } from 'redux/slices/integration';

import classes from '../Guesty/guesty.module.css';

import { ReactComponent as OwnerRezLogo } from 'asset/icons/ownerrez-logo.svg';

import ActivateUsingOauth from '../../components/ActivateUsingOauth/index';
import IntegrationHeader from '../../components/IntegrationHeader';
import { toasterMessage } from '../../constants';
import useIntegrationToaster from '../../hooks/useIntegrationToaster';
import DeactivateModal from '../Guesty/components/DeactivateModal';
import HomePageLayout from '../Guesty/components/HomePageLayout';
import ViewListings from '../Guesty/components/ViewListings';

const { PROPERTY_RENTALS } = config;

const OwnerRezPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const OWNERREZ = 'OwnerRez';
    const code = queryParams.get('code');
    const oauthError = queryParams.get('error');
    const clientId = process.env.REACT_APP_OWNERREZ_CLIENT_ID;
    const { integrationName } = useParams();
    const { showSuccessToast, showErrorToast } = useIntegrationToaster();
    const [deactivateIntegration] = useDeactivateIntegration();
    const [exchangeToken] = useExchangeToken();

    const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);

    const [isConnectingOwnerRez, setIsConnectingOwnerRez] = useState(false);
    const [isExchangingToken, setIsExchangingToken] = useState(false);

    const [isConnectionError, setIsConnectionError] = useState(false);

    const companyAccessCode = useSelector(selectCompanyAccessCode);
    const activeIntegrations = useSelector(selectIntegrations);

    const isActive = Boolean(useSelector(selectOwnerRezActiveState) ?? 0);

    const { data } = useGetDefaultBrandQuery(
        { integrationName },
        {
            skip: !isActive,
            refetchOnMountOrArgChange: true,
        }
    );

    const settingId = activeIntegrations?.find((key) => key.name === OWNERREZ).id;

    const defaultBrandUuid = data?.uuid;

    const onCloseDeactivateModal = () => {
        setOpenDeactivateModal(false);
    };

    const onClickDeactivate = () => {
        setOpenDeactivateModal(true);
    };

    const onDeactivateIntegration = async () => {
        const payload = {
            companyAccessCode,
            key: {
                id: settingId,
            },
        };

        setOpenDeactivateModal(false);
        try {
            dispatch({ type: actionConstants.START_LOADER });

            await deactivateIntegration(payload).unwrap();

            showSuccessToast(toasterMessage.TOKEN_DEACTIVATION_SUCCESS);
            const ownerrezIndex = activeIntegrations?.findIndex((item) => item.name === OWNERREZ);

            const integrationsCopy = [...activeIntegrations];

            integrationsCopy[ownerrezIndex] = {
                ...integrationsCopy[ownerrezIndex],
                active: 0,
            };

            dispatch(setActiveIntegrations(integrationsCopy));
        } catch (err) {
            showErrorToast(err, toasterMessage.TOKEN_DEACTIVATION_FAILED);
        } finally {
            dispatch({ type: actionConstants.STOP_LOADER });
        }
    };

    const onClickViewAll = () => {
        setOpenViewModal(true);
    };

    const startExchangeToken = useCallback(async () => {
        try {
            const payload = { code };

            setIsExchangingToken(true);

            await exchangeToken({ payload, integrationName }).unwrap();

            showSuccessToast(toasterMessage.TOKEN_ACTIVATION_SUCCESS);

            const ownerrezIndex = activeIntegrations?.findIndex((item) => item.name === OWNERREZ);
            const integrationsCopy = [...activeIntegrations];

            integrationsCopy[ownerrezIndex] = {
                ...integrationsCopy[ownerrezIndex],
                active: 1,
            };

            dispatch(setActiveIntegrations(integrationsCopy));
            history.replace('/integrations/ownerrez');
        } catch (error) {
            setIsConnectingOwnerRez(true);
            setIsConnectionError(true);
            showErrorToast(error, toasterMessage.TOKEN_ACTIVATION_FAILED, classes);
        } finally {
            setIsExchangingToken(false);
        }
    }, [
        code,
        integrationName,
        exchangeToken,
        dispatch,
        history,
        showSuccessToast,
        showErrorToast,
        activeIntegrations,
    ]);

    useEffect(() => {
        if (isActive || isConnectionError || isExchangingToken) {
            return;
        }

        if (oauthError) {
            setIsConnectingOwnerRez(true);
            setIsConnectionError(true);
            return;
        }

        if (!code) {
            return;
        }

        startExchangeToken();
    }, [code, oauthError, isActive, isExchangingToken, isConnectionError, startExchangeToken]);

    return (
        <Dashboard mainContainerStyles={classes.guestyActivePageWrapper}>
            <>
                <IntegrationHeader
                    title="OwnerRez Integrations"
                    link="https://info.authenticate.com/ownerrez-integration"
                />
                {isActive ? (
                    <HomePageLayout
                        onClickDeactivate={onClickDeactivate}
                        onClickViewAll={onClickViewAll}
                        brandUuid={defaultBrandUuid}
                    />
                ) : (
                    <ActivateUsingOauth
                        isConnecting={isConnectingOwnerRez}
                        isConnectionError={isConnectionError}
                        isExchangingToken={isExchangingToken}
                        connectUrl={`${PROPERTY_RENTALS.OWNERREZ.CONNECT_URL}&client_id=${clientId}`}
                        Logo={OwnerRezLogo}
                        integrationName={OWNERREZ}
                    />
                )}
                <DeactivateModal
                    open={openDeactivateModal}
                    setOpen={setOpenDeactivateModal}
                    onClose={onCloseDeactivateModal}
                    onConfirm={onDeactivateIntegration}
                />
                <ViewListings
                    brandUuid={defaultBrandUuid}
                    open={openViewModal}
                    onClose={() => setOpenViewModal(false)}
                />
            </>
        </Dashboard>
    );
};

export default OwnerRezPage;
