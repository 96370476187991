import config from 'config';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { fromPromise } from 'neverthrow';
import superAgent from 'superagent';

const { API } = config;

const HERE_API_KEY = process.env.REACT_APP_HERE_API_KEY;

countries.registerLocale(enLocale);

const countryObj = countries.getNames('en', { select: 'official' });

export const countryList = Object.entries(countryObj).map(([key, value]) => {
    return {
        label: value,
        value: countries.alpha2ToAlpha3(key),
    };
});

export const getUserAddress = async ({ zipCode, countryCode }) => {
    const response = await fromPromise(
        superAgent('get', API.HERE_GEOCODE_URL).query({
            apiKey: HERE_API_KEY,
            qq: `postalCode=${zipCode};country=${countryCode}`,
        }),
        (error) => error
    );

    if (response.isOk()) {
        const value = response.value.body;

        const userAddress = value.items?.[0]?.address ?? {};

        return userAddress;
    }
};

export const countryCodeToCountryName = (code) => {
    return countryList.find((country) => country.value === code)?.label ?? code;
};

export const countryNameToCode = (name) =>
    countryList.find((country) => country.label.toLowerCase() === name?.toLowerCase())?.value ??
    name;
