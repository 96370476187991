import actionDispatcher from 'api/actionDispatcher';
import {
    getPaymentMethod,
    getStripeCustomer,
    setupIntent,
    removeCard,
    createMethod,
    fetchCustomSenderEmail,
    updateCustomSenderEmail,
    deleteCustomSenderEmail,
    submitVerifyUINotification,
    getAccessCodeAPI,
    createCustomSenderEmail,
    setWebhookUrlAPI,
    getWebhookUrlAPI,
    getAdminLists,
    createNewMemberApi,
    updateMemberApi,
    resendInvitationMemberApi,
    reActivateMemberApi,
    changeMemberRoleApi,
    suspendMemberApi,
} from 'api/settings';

import actionType from './actionConstant';

export const paymentMethodsAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: getPaymentMethod.bind(null, Data, token),
        actionTypeSuccess: actionType.PAYMENT_METHOD_SUCCESS,
        actionTypeFailure: actionType.PAYMENT_METHOD_FAILURE,
        actionTypeInProgress: actionType.PAYMENT_METHOD_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const stripeCustomerAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: getStripeCustomer.bind(null, Data, token),
        actionTypeSuccess: actionType.STRIPE_CUSTOMER_SUCCESS,
        actionTypeFailure: actionType.STRIPE_CUSTOMER_FAILURE,
        actionTypeInProgress: actionType.STRIPE_CUSTOMER_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const setupIntentAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: setupIntent.bind(null, Data, token),
        actionTypeSuccess: actionType.SETUP_INTENT_SUCCESS,
        actionTypeFailure: actionType.SETUP_INTENT_FAILURE,
        actionTypeInProgress: actionType.SETUP_INTENT_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const createMethodAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: createMethod.bind(null, Data, token),
        actionTypeSuccess: actionType.CREATE_METHOD_SUCCESS,
        actionTypeFailure: actionType.CREATE_METHOD_FAILURE,
        actionTypeInProgress: actionType.CREATE_METHOD_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const removeCardAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: removeCard.bind(null, Data, token),
        actionTypeSuccess: actionType.REMOVE_CARD_SUCCESS,
        actionTypeFailure: actionType.REMOVE_CARD_FAILURE,
        actionTypeInProgress: actionType.REMOVE_CARD_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const fetchCustomSenderEmailAction = (
    { token, companyUUID },
    commonConfig = { loader: false },
    cb
) =>
    actionDispatcher({
        serviceMethod: fetchCustomSenderEmail.bind(null, token, companyUUID),
        actionTypeSuccess: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.FETCH.SUCCESS,
        actionTypeFailure: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.FETCH.FAILURE,
        actionTypeInProgress: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.FETCH.INPROGRESS,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const setCustomSenderEmailAction = (
    { token, companyUUID, email, senderName },
    commonConfig = { loader: false },
    cb
) =>
    actionDispatcher({
        serviceMethod: createCustomSenderEmail.bind(null, token, companyUUID, senderName, email),
        actionTypeSuccess: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.SUCCESS,
        actionTypeFailure: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.FAILURE,
        actionTypeInProgress: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.INPROGRESS,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const removeCustomSenderEmailAction = (
    { token, companyUUID },
    commonConfig = { loader: true },
    cb
) =>
    actionDispatcher({
        serviceMethod: deleteCustomSenderEmail.bind(null, token, companyUUID),
        actionTypeSuccess: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.SUCCESS,
        actionTypeFailure: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.FAILURE,
        actionTypeInProgress: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.INPROGRESS,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const setShowModal = (value) => {
    return {
        type: actionType.SHOW_MODAL,
        payload: value,
    };
};

export const setVerifyUINotificationAction = (
    { token, companyUUID, status },
    commonConfig = { loader: true },
    cb
) =>
    actionDispatcher({
        serviceMethod: submitVerifyUINotification.bind(null, token, companyUUID, status),
        actionTypeSuccess: actionType.VERIFYUI_NOTIFICATION_SUCCESS,
        actionTypeFailure: actionType.VERIFYUI_NOTIFICATION_FAILURE,
        actionTypeInProgress: actionType.VERIFYUI_NOTIFICATION_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const getAccessCodeAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: getAccessCodeAPI.bind(null, Data, token),
        actionTypeSuccess: actionType.GET_ACCESS_CODE_SUCCESS,
        actionTypeFailure: actionType.GET_ACCESS_CODE_FAILURE,
        actionTypeInProgress: actionType.GET_ACCESS_CODE_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const updateCustomSenderEmailAction = (
    { token, companyUUID, email, senderName },
    commonConfig,
    cb
) =>
    actionDispatcher({
        serviceMethod: updateCustomSenderEmail.bind(null, token, companyUUID, senderName, email),
        actionTypeSuccess: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.SUCCESS,
        actionTypeFailure: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.FAILURE,
        actionTypeInProgress: actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.INPROGRESS,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const setWebhookUrlAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: setWebhookUrlAPI.bind(null, Data, token),
        actionTypeSuccess: actionType.SET_WEBHOOK_URL_SUCCESS,
        actionTypeFailure: actionType.SET_WEBHOOK_URL_FAILURE,
        actionTypeInProgress: actionType.SET_WEBHOOK_URL_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const getWebhookUrlAction = (commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: getWebhookUrlAPI.bind(null, token),
        actionTypeSuccess: actionType.GET_WEBHOOK_URL_SUCCESS,
        actionTypeFailure: actionType.GET_WEBHOOK_URL_SUCCESS,
        actionTypeInProgress: actionType.GET_WEBHOOK_URL_SUCCESS,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const getAdminListsAction = (data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: getAdminLists.bind(null, data, token),
        actionTypeSuccess: actionType.GET_COMPANY_ADMINS_SUCCESS,
        actionTypeFailure: actionType.GET_COMPANY_ADMINS_FAILURE,
        actionTypeInProgress: actionType.GET_COMPANY_ADMINS_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const createNewMemberAction = (data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: createNewMemberApi.bind(null, data, token),
        actionTypeSuccess: actionType.CREATE_AN_MEMBER_SUCCESS,
        actionTypeFailure: actionType.CREATE_AN_MEMBER_FAILURE,
        actionTypeInProgress: actionType.CREATE_AN_MEMBER_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const updateMemberAction = (data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: updateMemberApi.bind(null, data, token),
        actionTypeSuccess: actionType.UPDATE_MEMBER_SUCCESS,
        actionTypeFailure: actionType.UPDATE_MEMBER_FAILURE,
        actionTypeInProgress: actionType.UPDATE_MEMBER_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const resendInvitationMemberAction = (data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: resendInvitationMemberApi.bind(null, data, token),
        actionTypeSuccess: actionType.RESEND_INVITATION_MEMBER_SUCCESS,
        actionTypeFailure: actionType.RESEND_INVITATION_MEMBER_FAILURE,
        actionTypeInProgress: actionType.RESEND_INVITATION_MEMBER_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const reActivateMemberAction = (data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: reActivateMemberApi.bind(null, data, token),
        actionTypeSuccess: actionType.RE_ACTIVATE_MEMBER_SUCCESS,
        actionTypeFailure: actionType.RE_ACTIVATE_MEMBER_FAILURE,
        actionTypeInProgress: actionType.RE_ACTIVATE_MEMBER_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const changeMemberRoleAction = (data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: changeMemberRoleApi.bind(null, data, token),
        actionTypeSuccess: actionType.CHANGE_MEMBER_ROLE_SUCCESS,
        actionTypeFailure: actionType.CHANGE_MEMBER_ROLE_FAILURE,
        actionTypeInProgress: actionType.CHANGE_MEMBER_ROLE_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const suspendMemberAction = (data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: suspendMemberApi.bind(null, data, token),
        actionTypeSuccess: actionType.SUSPEND_MEMBER_SUCCESS,
        actionTypeFailure: actionType.SUSPEND_MEMBER_FAILURE,
        actionTypeInProgress: actionType.SUSPEND_MEMBER_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const setSidebarCollapse = (data) => {
    return { type: actionType.TOGGLE_SIDEBAR, payload: data };
};

export const showNavigationPreview = () => {
    return { type: actionType.SHOW_NAVIGATION_PREVIEW_MODAL };
};

export const setWarningPopup = (type, show) => ({
    type: actionType.SET_WARNING_POPUP,
    payload: { type, show },
});
