import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import config from 'config';
import Dashboard from 'layouts/V2/Dashboard';

import actionConstants from 'redux/actions/actionConstant';
import { selectCompanyAccessCode } from 'redux/selectors/auth';
import { selectIntegrations, selectHostfullyActiveState } from 'redux/selectors/integrations';
import {
    useGetDefaultBrandQuery,
    useDeactivateIntegration,
    useExchangeToken,
} from 'redux/slices/globalApi';
import { setActiveIntegrations } from 'redux/slices/integration';

import classes from '../Guesty/guesty.module.css';

import { ReactComponent as HostfullyLogo } from 'asset/icons/hostfully-logo.svg';

import ActivateUsingOauth from '../../components/ActivateUsingOauth/index';
import IntegrationHeader from '../../components/IntegrationHeader';
import { HOSTFULLY_CONNECT_STATUS, toasterMessage } from '../../constants';
import useIntegrationToaster from '../../hooks/useIntegrationToaster';
import DeactivateModal from '../Guesty/components/DeactivateModal';
import HomePageLayout from '../Guesty/components/HomePageLayout';
import ViewListings from '../Guesty/components/ViewListings';

const { PROPERTY_RENTALS, APPLICATION } = config;

const HostfullyPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const code = queryParams.get('code');
    const status = queryParams.get('status');

    const { integrationName } = useParams();
    const { showSuccessToast, showErrorToast } = useIntegrationToaster();
    const [deactivateIntegration] = useDeactivateIntegration();
    const [exchangeToken] = useExchangeToken();

    const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);

    const [isConnectingHostfully, setIsConnectingHostfully] = useState(false);
    const [isExchangingToken, setIsExchangingToken] = useState(false);

    const [isConnectionError, setIsConnectionError] = useState(false);

    const companyAccessCode = useSelector(selectCompanyAccessCode);
    const activeIntegrations = useSelector(selectIntegrations);

    const isActive = Boolean(useSelector(selectHostfullyActiveState) ?? 0);
    const clientId = process.env.REACT_APP_HOSTFULLY_CLIENT_ID;
    const HOSTFULLY = 'Hostfully';

    const { data } = useGetDefaultBrandQuery(
        { integrationName },
        {
            skip: !isActive,
            refetchOnMountOrArgChange: true,
        }
    );

    const settingId = activeIntegrations?.find((key) => key.name === HOSTFULLY).id;

    const defaultBrandUuid = data?.uuid;

    const onCloseDeactivateModal = () => {
        setOpenDeactivateModal(false);
    };

    const onClickDeactivate = () => {
        setOpenDeactivateModal(true);
    };

    const onDeactivateIntegration = async () => {
        const payload = {
            companyAccessCode,
            key: {
                id: settingId,
            },
        };

        setOpenDeactivateModal(false);
        try {
            dispatch({ type: actionConstants.START_LOADER });

            await deactivateIntegration(payload).unwrap();

            showSuccessToast(toasterMessage.TOKEN_DEACTIVATION_SUCCESS);
            const hostfullyIndex = activeIntegrations?.findIndex(
                (item) => item.name === 'Hostfully'
            );

            const integrationsCopy = [...activeIntegrations];

            integrationsCopy[hostfullyIndex] = {
                ...integrationsCopy[hostfullyIndex],
                active: 0,
            };

            dispatch(setActiveIntegrations(integrationsCopy));
        } catch (err) {
            showErrorToast(err, toasterMessage.TOKEN_DEACTIVATION_FAILED);
        } finally {
            dispatch({ type: actionConstants.STOP_LOADER });
        }
    };

    const onClickViewAll = () => {
        setOpenViewModal(true);
    };

    const startExchangeToken = useCallback(async () => {
        try {
            const payload = { code };

            setIsExchangingToken(true);

            await exchangeToken({ payload, integrationName }).unwrap();

            showSuccessToast(toasterMessage.TOKEN_ACTIVATION_SUCCESS);

            const hostfullyIndex = activeIntegrations?.findIndex((item) => item.name === HOSTFULLY);
            const integrationsCopy = [...activeIntegrations];

            integrationsCopy[hostfullyIndex] = {
                ...integrationsCopy[hostfullyIndex],
                active: 1,
            };

            dispatch(setActiveIntegrations(integrationsCopy));
            history.replace('/integrations/hostfully');
        } catch (error) {
            setIsConnectionError(true);
            showErrorToast(error, toasterMessage.TOKEN_ACTIVATION_FAILED, classes);
        } finally {
            setIsExchangingToken(false);
        }
    }, [
        code,
        integrationName,
        exchangeToken,
        dispatch,
        history,
        showSuccessToast,
        showErrorToast,
        activeIntegrations,
    ]);

    useEffect(() => {
        if (isActive || isConnectionError || isExchangingToken) {
            return;
        }

        if (!status && !code) {
            return;
        }

        if (status !== HOSTFULLY_CONNECT_STATUS.SUCCESSFUL) {
            setIsConnectingHostfully(true);
            setIsConnectionError(true);
            return;
        }

        if (status === HOSTFULLY_CONNECT_STATUS.SUCCESSFUL) startExchangeToken();
    }, [code, status, isActive, isExchangingToken, isConnectionError, startExchangeToken]);

    return (
        <Dashboard mainContainerStyles={classes.guestyActivePageWrapper}>
            <>
                <IntegrationHeader
                    title="Hostfully Integrations"
                    link="https://info.authenticate.com/hostfully-integration"
                />
                {isActive ? (
                    <HomePageLayout
                        onClickDeactivate={onClickDeactivate}
                        onClickViewAll={onClickViewAll}
                        brandUuid={defaultBrandUuid}
                    />
                ) : (
                    <ActivateUsingOauth
                        isConnecting={isConnectingHostfully}
                        isConnectionError={isConnectionError}
                        isExchangingToken={isExchangingToken}
                        connectUrl={`${PROPERTY_RENTALS.HOSTFULLY.CONNECT_URL}&clientId=${clientId}&state=authenticate&redirectUri=${APPLICATION.URL}/integrations/hostfully`}
                        Logo={HostfullyLogo}
                        integrationName={HOSTFULLY}
                    />
                )}

                <DeactivateModal
                    open={openDeactivateModal}
                    setOpen={setOpenDeactivateModal}
                    onClose={onCloseDeactivateModal}
                    onConfirm={onDeactivateIntegration}
                />
                <ViewListings
                    brandUuid={defaultBrandUuid}
                    open={openViewModal}
                    onClose={() => setOpenViewModal(false)}
                />
            </>
        </Dashboard>
    );
};

export default HostfullyPage;
