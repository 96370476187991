import { useHistory, useParams } from 'react-router-dom';

import { PROPERTY_RENTALS } from '../constants';
import Guesty from './Guesty';
import Hostaway from './Hostaway';
import Hostfully from './Hostfully';
import Lodgify from './Lodgify';
import OwnerRez from './OwnerRez';

function PropertyRentalPage(props) {
    const { integrationName } = useParams();
    const history = useHistory();
    const renderIntegrationComponent = () => {
        switch (integrationName) {
            case PROPERTY_RENTALS.GUESTY:
                return <Guesty {...props} />;
            case PROPERTY_RENTALS.HOSTAWAY:
                return <Hostaway {...props} />;
            case PROPERTY_RENTALS.LODGIFY:
                return <Lodgify {...props} />;
            case PROPERTY_RENTALS.HOSTFULLY:
                return <Hostfully {...props} />;
            case PROPERTY_RENTALS.OWNERREZ:
                return <OwnerRez {...props} />;
            default:
                return history.push(`/integrations`);
        }
    };

    return <>{renderIntegrationComponent()}</>;
}

export default PropertyRentalPage;
