import actionType from 'redux/actions/actionConstant';

const initialState = {
    paymentMethod: undefined,
    intent: undefined,
    paymentMethods: undefined,
    showModal: true,
    accessCode: undefined,
    webhookUrl: undefined,
    companyAdmins: undefined,
    // handle sidebar collapse and expand state
    sidebarCollapse: false,
    navigationPreview: false,
    // SOC & GWC check warning popup - Userprofile
    // Any warning popup that have similar functionality, add here
    warningPopups: {
        SOC: true,
        GWC: true,
    },
};

export default function settings(state = initialState, action) {
    switch (action.type) {
        case actionType.SETUP_INTENT_SUCCESS:
            return {
                ...state,
                intent: action.payload.intent,
            };

        case actionType.PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: action.payload.paymentMethods,
                intent: undefined,
            };

        case actionType.REMOVE_CARD_SUCCESS:
            return {
                ...state,
                paymentMethods: [],
            };

        case actionType.RESET_ALL_REDUCERS:
            return {
                ...state,
                active: undefined,
                showModal: false,
                customSenderEmail: null,
                paymentMethods: undefined,
                accessCode: undefined,
                webhookUrl: undefined,
                companyAdmins: undefined,
            };

        case actionType.SETTINGS.CUSTOM_SENDER_EMAIL.UPDATE.SUCCESS:
        case actionType.SETTINGS.CUSTOM_SENDER_EMAIL.FETCH.SUCCESS:
            return {
                ...state,
                customSenderEmail: action.payload.customSenderEmail,
            };

        case actionType.SETTINGS.CUSTOM_SENDER_EMAIL.DELETE.SUCCESS:
            return {
                ...state,
                customSenderEmail: null,
            };

        case actionType.LOGIN_SUCCESS:
            return {
                ...state,
                showModal: true,
            };

        case actionType.SHOW_MODAL:
            return {
                ...state,
                showModal: action.payload,
            };

        case actionType.TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebarCollapse: action.payload,
            };

        case actionType.SHOW_NAVIGATION_PREVIEW_MODAL:
            return {
                ...state,
                navigationPreview: true,
            };

        case actionType.SET_WARNING_POPUP:
            return {
                ...state,
                warningPopups: {
                    ...state.warningPopups,
                    [action.payload.type]: action.payload.show,
                },
            };

        case actionType.GET_ACCESS_CODE_SUCCESS:
            return {
                ...state,
                accessCode: action.payload,
            };

        case actionType.GET_WEBHOOK_URL_SUCCESS:
            return {
                ...state,
                webhookUrl: action.payload,
            };

        case actionType.GET_COMPANY_ADMINS_SUCCESS:
            return {
                ...state,
                companyAdmins: action.payload,
            };

        default:
            return state;
    }
}
